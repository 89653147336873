import React from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Input, Label } from "reactstrap";

function UploadInput({ title, name, handleUpload, value, data }) {
  const [t] = useTranslation("DOCUMENTS");
  return (
    <div className="file-upload-container" title={title}>
      <p>{value ? "Caricato" : "Non caricato"}</p>
      <FormGroup>
        <Label
          className={`custom-label${data.status === true ? "" : " disabled"}`}
          for={name + data.id}
        >
          {t("UPLOAD")}
        </Label>
        <Input
          id={name + data.id}
          disabled={data.status === true ? false : true}
          onChange={(e) => handleUpload(e, data.id)}
          type="file"
          name={name}
          hidden
        />
      </FormGroup>
    </div>
  );
}

export default UploadInput;
