import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { I18nextProvider } from "react-i18next";
import { QueryParamProvider } from "use-query-params";
import { Provider as AlertProvider, positions } from "react-alert";
import i18next from "i18next";
import moment from "moment";
import "moment/locale/it";
import PrivateRoute from "./commons/Auth/PrivateRoute";
import PublicRoute from "./commons/Auth/PublicRoute";
import AlertTemplate from "./commons/AlertTemplate";
import { LoadingProvider } from "./commons/Loading";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import Reset from "./pages/Reset";
import Signup from "./pages/Signup";
import Verification from "./pages/Verification";
import Dashboard from "./pages/Dashboard";
import Table from "./pages/Table";
import PageNotFound from "./pages/PageNotFound";
import translations from "./translations";
import "./App.scss";
import Authentication from "./pages/Authentication";
import OidcProvider from "./commons/Auth/OidcProvider";
import Logout from "./pages/Logout/Logout";
import UserProvider from "./commons/Auth/UserProvider";

i18next.init({
  resources: translations,
  lng: "it",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

moment.locale("it");

function App() {
  return (
    <div className="App">
      <I18nextProvider i18n={i18next}>
        <AlertProvider
          template={AlertTemplate}
          position={positions.TOP_RIGHT}
          timeout={5000}
        >
          <CookiesProvider>
            <LoadingProvider>
              <OidcProvider>
                <UserProvider>
                  <Router>
                    <QueryParamProvider ReactRouterRoute={Route}>
                      <Switch>
                        <PublicRoute exact path="/" component={Login} />
                        <PublicRoute path="/signup" component={Signup} />
                        <PublicRoute path="/forgot" component={Forgot} />
                        <PublicRoute path="/reset/:email?" component={Reset} />
                        <PublicRoute
                          path="/verification"
                          component={Verification}
                        />
                        <PublicRoute
                          path="/authentication"
                          component={Authentication}
                        />
                        <PrivateRoute path="/dashboard" component={Dashboard} />
                        <PrivateRoute path="/table" component={Table} />
                        <PrivateRoute path="/logout" component={Logout} />
                        <Route component={PageNotFound} />
                      </Switch>
                    </QueryParamProvider>
                  </Router>
                </UserProvider>
              </OidcProvider>
            </LoadingProvider>
          </CookiesProvider>
        </AlertProvider>
      </I18nextProvider>
    </div>
  );
}

export default App;
