import React from 'react';
import './Footer.scss';
import packageJson from './../../../package.json';

function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer>
      <div className="row">
        <div className="col-6">Cookie Policy - Privacy Policy</div>
        <div className="col-6 text-right">
          Copyright® {year} v.{packageJson.version}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
