import React from 'react';

const getTitles = (components = []) => {
  if (!Array.isArray(components)) {
    return [];
  }

  const titles = components.map((component = {}) => {
    const { props = {} } = component;
    const { title = '' } = props;
    return title;
  });

  return titles;
};

const DataTableHeader = ({ children = {} }) => {
  const titles = getTitles(children);

  return (
    <thead>
      <tr>
        {titles.map((title = '', key) => (
          <th key={key}>{title}</th>
        ))}
      </tr>
    </thead>
  );
};

export { getTitles, DataTableHeader };
