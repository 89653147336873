import React from "react";

function DownloadInput({ title, name, downloadDocument, value, data }) {
  return (
    <div title={title}>
      <button
        id={data.id}
        name={name}
        disabled={value ? false : true}
        onClick={() => downloadDocument(value)}
        className="primary-button"
        title={title}
      >
        Download
      </button>
    </div>
  );
}

export default DownloadInput;
