import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { ReactComponent as IconDashboard } from "../../images/icn_dashboard.svg";
import { ReactComponent as IconUsers } from "../../images/icn_utenti.svg";
import "./LinkMenu.scss";

function LinkMenu({ match }) {
  const { url } = match || {};

  const getActive = (url = "", paths = []) => {
    return paths.some((path) => url.startsWith(path)) ? "active" : "";
  };

  return (
    <div>
      <Link to={"/table"} className={getActive(url, ["/table", "/detail/"])}>
        <IconUsers className="hover-color" />
      </Link>
      <Link to={"/dashboard"} className={getActive(url, ["/dashboard"])}>
        <IconDashboard className="hover-color" />
      </Link>
    </div>
  );
}

export default withRouter(LinkMenu);
