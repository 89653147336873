import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Menu from '../../commons/Menu';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import { ReactComponent as IconDashboard } from '../../images/icn_dashboard.svg';
import './Dashboard.scss';

function DashboardPresentational({ match }) {
  const [t] = useTranslation('COMMON');

  return (
    <div className="App">
      <Menu match={match} />

      <div className="corpo">
        <section>
          <Header
            title={t('COMMON:DASHBOARD')}
            icon={<IconDashboard fill="#3c8dbc" />}
          />

          <iframe
            style={{ width: '100%', height: '750px' }}
            title="dashboard"
            src="https://datastudio.google.com/embed/reporting/99ef2375-61a9-427c-aec9-214d2898fd28/page/1M"
            frameBorder="0"
            allowFullScreen=""
          ></iframe>
        </section>
      </div>

      <BurgerMenu />
    </div>
  );
}

export default withRouter(DashboardPresentational);
