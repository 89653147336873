import axios from "axios";

const fetchMe = async (token) => {
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}me`,
      headers: {
        Authorization: token,
      },
    });
    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export default fetchMe;
