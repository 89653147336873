import React from 'react';
import './Menu.scss';
import LinkMenu from '../../components/LinkMenu';

function Menu({ match }) {
  return (
    <div className="nav-desktop">
      <nav>
        <LinkMenu />
      </nav>
    </div>
  );
}

export default Menu;
