import React from "react";
import { Table } from "reactstrap";
import "./ModalTable.scss";
import { ReactComponent as IconApprove } from "../../images/icn-approve.svg";
import { ReactComponent as IconDeny } from "../../images/icn-deny.svg";
import { ReactComponent as IconDownload } from "../../images/icn-download.svg";
import { ReactComponent as IconTrash } from "../../images/icn-trash.svg";
import "react-datepicker/dist/react-datepicker.css";

function ModalTable({ data = {}, downloadDocument, refuseDocument }) {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Tipo</th>
          <th>Stato</th>
          <th>Scarica</th>
          <th>Rifiuta</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Carta d'identità</td>
          <td>
            {data.identityCard ? (
              <div className="approve-badge">
                <IconApprove />
              </div>
            ) : (
              <div className="deny-badge">
                <IconDeny />
              </div>
            )}
          </td>
          <td>
            <button
              disabled={data.identityCard ? false : true}
              onClick={(e) => downloadDocument(data.identityCard)}
              className="primary-button"
            >
              <IconDownload />
            </button>
          </td>
          <td>
            <button
              onClick={() => refuseDocument(data.id, "identityCard")}
              disabled={data.identityCard ? false : true}
              className="secondary-button"
            >
              <IconTrash />
            </button>
          </td>
        </tr>
        <tr>
          <td>Visura camerale</td>
          <td>
            {data.cameral ? (
              <div className="approve-badge">
                <IconApprove />
              </div>
            ) : (
              <div className="deny-badge">
                <IconDeny />
              </div>
            )}
          </td>
          <td>
            <button
              disabled={data.cameral ? false : true}
              onClick={(e) => downloadDocument(data.cameral)}
              className="primary-button"
            >
              <IconDownload />
            </button>
          </td>
          <td>
            <button
              onClick={() => refuseDocument(data.id, "cameral")}
              disabled={data.cameral ? false : true}
              className="secondary-button"
            >
              <IconTrash />
            </button>
          </td>
        </tr>
        <tr>
          <td>Codice fiscale</td>
          <td>
            {data.fiscalCode ? (
              <div className="approve-badge">
                <IconApprove />
              </div>
            ) : (
              <div className="deny-badge">
                <IconDeny />
              </div>
            )}
          </td>
          <td>
            <button
              disabled={data.fiscalCode ? false : true}
              onClick={(e) => downloadDocument(data.fiscalCode)}
              className="primary-button"
            >
              <IconDownload />
            </button>
          </td>
          <td>
            <button
              onClick={() => refuseDocument(data.id, "fiscalCode")}
              disabled={data.fiscalCode ? false : true}
              className="secondary-button"
            >
              <IconTrash />
            </button>
          </td>
        </tr>
        <tr>
          <td>Iscrizione IVASS</td>
          <td>
            {data.ivass ? (
              <div className="approve-badge">
                <IconApprove />
              </div>
            ) : (
              <div className="deny-badge">
                <IconDeny />
              </div>
            )}
          </td>
          <td>
            <button
              disabled={data.ivass ? false : true}
              onClick={(e) => downloadDocument(data.ivass)}
              className="primary-button"
            >
              <IconDownload />
            </button>
          </td>
          <td>
            <button
              onClick={() => refuseDocument(data.id, "ivass")}
              disabled={data.ivass ? false : true}
              className="secondary-button"
            >
              <IconTrash />
            </button>
          </td>
        </tr>
        <tr>
          <td>Polizza RC Professionale</td>
          <td>
            {data.rcInsurance ? (
              <div className="approve-badge">
                <IconApprove />
              </div>
            ) : (
              <div className="deny-badge">
                <IconDeny />
              </div>
            )}
          </td>
          <td>
            <button
              disabled={data.rcInsurance ? false : true}
              onClick={(e) => downloadDocument(data.rcInsurance)}
              className="primary-button"
            >
              <IconDownload />
            </button>
          </td>
          <td>
            <button
              onClick={() => refuseDocument(data.id, "rcInsurance")}
              disabled={data.rcInsurance ? false : true}
              className="secondary-button"
            >
              <IconTrash />
            </button>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default ModalTable;
