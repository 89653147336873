import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import './BurgerMenu.scss';
import LinkMenu from '../../components/LinkMenu';
import burgerIcon from '../../images/icn-menu.svg';

function BurgerMenu() {
  return (
    <div className="burger-menu">
      <Menu width={'130px'} customBurgerIcon={<img src={burgerIcon} alt="" />}>
        <LinkMenu />
      </Menu>
    </div>
  );
}

export default BurgerMenu;
