import React from "react";
import { useTranslation } from "react-i18next";
import LogoHeader from "../../commons/LogoHeader/index.js";
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import "./Login.scss";
import { useOidc } from "@axa-fr/react-oidc";

function Login({ match }) {
  const { t } = useTranslation("LOGIN");
  const { login } = useOidc();

  return (
    <div className="App">
      <LogoHeader match={match} />

      <section>
        <div className="container">
          <Card className="u-center-horizontal login-card">
            <CardHeader>
              <CardTitle>
                <span>{t("DO_LOGIN")}</span>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Button
                className="u-center-horizontal login-button"
                type="primary"
                color="primary"
                onClick={() => login("/table")}
              >
                Login with AZ
              </Button>
            </CardBody>
          </Card>
        </div>
      </section>
    </div>
  );
}

export default Login;
