import { useContext, useEffect, useState } from 'react'
import UserContext from './UserContext.js';
import { useOidcAccessToken } from '@axa-fr/react-oidc'
import { useLoading } from '../Loading/index.js'
import fetchMe from '../../lib/fetch/me.js'
import Logout from '../../pages/Logout/Logout.jsx'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'

export default function UserProvider({ children }) {
  const { t } = useTranslation("LOGIN");
  const [user, setUser] = useState(null);
  const [toLogout, setToLogout] = useState(false);
  const { showLoading, hideLoading } = useLoading();
  const { accessToken } = useOidcAccessToken();
  const alert = useAlert();

  /*
  useEffect(() => {
    (async () => {
      if (accessToken) {
        showLoading();
        const { error, data } = await fetchMe(accessToken);

        if (error) {
          setToLogout(true);
          console.error(error);
          alert.error(t("COMMON:GENERIC_ERROR"));
        } else {
          setUser(data);
        }
        hideLoading();
      } else {
        setToLogout(false);
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])
  */
 
  
  useEffect(() => {
    (async () => {
      if (accessToken) {
        showLoading();
        const { error, data } = await fetchMe(accessToken);

        if (/*error*/false) {
          setToLogout(true);
          console.error(error);
          alert.error(t("COMMON:GENERIC_ERROR"));
        } else {
          setUser(data);
        }
        hideLoading();
      } else {
        setToLogout(false);
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  return (
    <UserContext.Provider value={{
      user,
      setUser,
    }}>
      { toLogout ? <Logout /> : children }
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
