import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { DataTable, Field, DateTimeField } from "../../commons/DataTable";
import UploadInput from "../../commons/UploadInput";
import ModalBlock from "../../commons/ModalBlock";
import StatusField from "./StatusField";
import DownloadInput from "../../commons/DownloadInput";

function DealsTable({
  data,
  isLoading,
  fetchdata,
  handleUpload,
  downloadDocument,
  refuseDocument,
}) {
  const [t] = useTranslation("COMMON");

  return (
    <DataTable
      data={data}
      isLoading={isLoading}
      loadingText={t("COMMON:LOADING")}
      emptyText={t("COMMON:NO_DATA")}
    >
      <Field title="id" source="id" />
      <Field title="Email" source="email" />
      <Field title="Tipo" source="type" />
      <Field title="Rui" source="agentRui" />
      <Field title="Codice Fisc. / P. IVA" source="agentFiscalCode" />
      <DateTimeField
        title="Creato il"
        format="DD/MM/YYYY"
        source="dataCreazione"
        className="created"
      />
      <ModalBlock
        title="Actions"
        downloadDocument={downloadDocument}
        refuseDocument={refuseDocument}
        fetchdata={fetchdata}
      />
      <StatusField title="Status" source="status" fetchdata={fetchdata} />
      <DownloadInput
        source="document"
        name="document"
        downloadDocument={downloadDocument}
        title="Accordo firmato dall'utente"
      />
      <UploadInput
        source="unsignedDocument"
        name={"document"}
        title="Accordo (firmato da Euler Hermes)"
        handleUpload={handleUpload}
      />
    </DataTable>
  );
}

export default withRouter(DealsTable);
