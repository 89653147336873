import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Proptypes from 'prop-types';
import { useOidcAccessToken } from '@axa-fr/react-oidc'

function PublicRoute({
  component: Component,
  privateRedirect = '/table',
  ...props
}) {
  const { accessToken } = useOidcAccessToken();

  return (
    <Route
      {...props}
      render={(props) =>
        accessToken ? (
          <Redirect
            push={false}
            to={{
              pathname: privateRedirect,
              state: { from: props.location },
            }}
          />
        ) : (
          <Component { ...props } />
        )
      }
    />
  );
}

PublicRoute.propTypes = {
  exact: Proptypes.bool,
  path: Proptypes.string,
  component: Proptypes.func.isRequired,
  privateDefaultPath: Proptypes.string,
};

export default PublicRoute;
