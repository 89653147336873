import React from "react";
import { ReactComponent as IconApprove } from "../../images/icn-approve.svg";
import { ReactComponent as IconDeny } from "../../images/icn-deny.svg";
import { confirmAlert } from "react-confirm-alert";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { useOidcAccessToken } from '@axa-fr/react-oidc'

const StatusField = ({ data, fetchdata }) => {
  const { t } = useTranslation("AGENTS");
  const alert = useAlert();
  const { accessToken } = useOidcAccessToken();
  const {
    id,
    email,
    status,
    ivass,
    identityCard,
    rcInsurance,
    fiscalCode,
    cameral,
  } = data;
  const buttonCheck =
    ivass && identityCard && rcInsurance && fiscalCode && cameral;
  const approved = async () => {
    confirmAlert({
      title: "Approvazione utente",
      message: `Approvare il profilo ${email}?`,
      buttons: [
        {
          label: "Approva",
          onClick: async () => {
            await axios({
              method: "PUT",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              url: `${process.env.REACT_APP_API_AGENTS}/approve/${id}`,
            });

            fetchdata();
            alert.info(t("AGENT_APPROVED"));
          },
        },
        {
          label: "Annulla",
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  const denied = async () => {
    confirmAlert({
      title: "Rifiuto utente",
      message: `Rifiutare il profilo ${email}?`,
      buttons: [
        {
          label: "Rifiuta",
          onClick: async () => {
            await axios({
              method: "PUT",
              url: `${process.env.REACT_APP_API_AGENTS}/deny/${id}`,
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });

            fetchdata();
            alert.info(t("AGENT_DENIED"));
          },
        },
        {
          label: "Annulla",
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  return (
    <div style={{ display: "flex" }}>
      {status == null ? (
        <>
          <button
            className="approve-button"
            disabled={!buttonCheck}
            style={{ margin: 0 }}
            onClick={approved}
          >
            <IconApprove />
          </button>
          <button
            className="deny-button"
            disabled={!buttonCheck}
            style={{ margin: 0 }}
            onClick={denied}
          >
            <IconDeny />
          </button>
        </>
      ) : (
        <>{status ? "Approvato" : "Rifiutato"}</>
      )}
    </div>
  );
};

export default StatusField;
