import { OidcProvider as AxaOidcProvider } from '@axa-fr/react-oidc';
import React, { useEffect } from 'react'
import { useLoading } from "../Loading";
import Logout from "../../pages/Logout/Logout.jsx";

const configuration = {
    authority: process.env.REACT_APP_IDP_ISSUER,
    client_id: process.env.REACT_APP_IDP_CLIENT_ID,
    redirect_uri: window.location.origin + '/authentication',
    scope: 'openid',
};

const OidcLoadingComponent = () => {
    const { showLoading, hideLoading } = useLoading();
    useEffect(() => {
        showLoading();

        return () => hideLoading();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <></>
};

export default function OidcProvider({children}) {
    return (
        <AxaOidcProvider
            configuration={configuration}
            loadingComponent={OidcLoadingComponent}
            authenticatingComponent={OidcLoadingComponent}
            callbackSuccessComponent={OidcLoadingComponent}
            sessionLostComponent={Logout}
            authenticatingErrorComponent={Logout}
        >
            {children}
        </AxaOidcProvider>
    );
}
