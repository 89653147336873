import React from "react";
import Table from "./Table";

function DataTable(props) {
  return (
    <>
      <Table {...props} />
      {/* <Pagination {...props} /> */}
    </>
  );
}

export default DataTable;
