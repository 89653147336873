//Ref. https://reacttraining.com/react-router/web/example/auth-workflow

import React from 'react';
import { Route } from 'react-router-dom';
import Proptypes from 'prop-types';
import { withOidcSecure } from '@axa-fr/react-oidc'

function PrivateRoute({
  component,
  ...props
}) {

  return (
    <Route
      {...props}
      component={withOidcSecure(component)}
    />
  );
}

PrivateRoute.propTypes = {
  component: Proptypes.func.isRequired,
};

export default PrivateRoute;
