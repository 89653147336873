import React from "react";
import { Card, CardBody } from "reactstrap";
import Menu from "../../commons/Menu";
import Header from "../../commons/Header";
import Footer from "../../commons/Footer";
import BurgerMenu from "../../commons/BurgerMenu";
import Table from "./Table";
import { ReactComponent as IconUsers } from "../../images/icn_utenti.svg";

function TablePresentational(props) {
  const {
    data,
    total = 0,
    query = {},
    onFilter,
    isLoading = false,
    fetchdata,
    refuseDocument,
    downloadDocument,
    handleUpload,
  } = props;

  return (
    <div className="App">
      <Menu />

      <div className="corpo">
        <section>
          <Header title="Utenti" icon={<IconUsers fill="#3c8dbc" />} />

          <Card>
            <CardBody>
              <Table
                data={data}
                total={total}
                query={query}
                onFilter={onFilter}
                isLoading={isLoading}
                fetchdata={fetchdata}
                handleUpload={handleUpload}
                downloadDocument={downloadDocument}
                refuseDocument={refuseDocument}
              />
            </CardBody>
          </Card>
        </section>
        <Footer />
      </div>

      <BurgerMenu />
    </div>
  );
}

export default TablePresentational;
