import React from "react";
import { Link } from "react-router-dom";
import "./LogoHeader.scss";
import logo from "../../images/new_logo.png";

function LogoHeader({ match }) {
  return (
    <section className="sct-start u-bg-light">
      <header className="App-header">
        <div className="container">
          <div className="header-logo">
            <Link to="/">
              <img src={logo} alt="" className="u-center-center img-fluid" />
            </Link>
          </div>
        </div>
      </header>
    </section>
  );
}

export default LogoHeader;
