import React from 'react';
import './PageNotFound.scss';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import { Col, Container, Row } from 'reactstrap';

function PageNotFound() {
  return (
    <section className="page-not-found">
      <Container className="flex-center">
        <Row>
          <Col xs="12">
            <div>
              <img src={logo} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div>
              <h1>404</h1>
              <p>We're sorry, we couldn't find the page you were looking for</p>
              <Link to={'/'}>
                <button className="primary-button">Torna alla home</button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default PageNotFound;
