import React, { useState, useEffect } from "react";
import { useQueryParams, StringParam } from "use-query-params";
import DashboardPresentational from "./DashboardPresentational";

function Dashboard(props) {
  const [isLoading] = useState(true);
  const [data] = useState({});
  //   const { t } = useTranslation('FORGOT');
  //   const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    from: StringParam,
    to: StringParam,
  });

  const { from, to } = query;

  useEffect(() => {
    // const fetchDashboard = async (query) => {
    //   setLoading(true);
    //   const { error, results } = await dashboard(query);
    //   if (error) {
    //     setData({});
    //     setLoading(false);
    //     alert.error(t('COMMON:GENERIC_ERROR'));
    //     return;
    //   }
    //   setData(results);
    //   setLoading(false);
    // };
    // fetchDashboard({ token, from, to });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  return (
    <DashboardPresentational
      query={query}
      onFilter={setQuery}
      isLoading={isLoading}
      data={data}
      {...props}
    />
  );
}

export default Dashboard;
