import React, {useEffect} from "react";
import {useLoading} from "../../commons/Loading";
import { useHistory } from 'react-router-dom'
import { useOidc } from '@axa-fr/react-oidc'

const Logout = () => {
    const { showLoading, hideLoading } = useLoading();
    const history = useHistory();
    const { logout } = useOidc();
    useEffect(() => {
        (async () => {
            showLoading();
            await logout();
            history.replace('/');
            hideLoading();
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
};

export default Logout;
